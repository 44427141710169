.nav-items {
	color: #282927;
	font-family: 'Outfit', sans-serif;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 24.5px; /* 122.5% */
}

.profile-email {
	color: var(--Inactive-menu-item, #7792b7);
	text-align: center;
	font-family: 'DM Sans', sans-serif;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.profile-no {
	color: var(--Inactive-menu-item, #7792b7);
	text-align: center;
	font-family: 'DM Sans', sans-serif;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.profile-link {
	color: var(--nea-blue, #147cbc);
	text-align: center;
	font-family: 'DM Sans', sans-serif;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	text-decoration-line: underline;
}

.pws-radio {
	color: var(--nea-blue, #147cbc);
	text-align: center;
	font-family: DM Sans;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 120%; /* 21.6px */
}

.profile-status {
	background: rgba(87, 168, 99, 0.21);
	color: #006f30;
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%; /* 28.5px */
}

.slanted-background-2 {
	background-blend-mode: normal;
	background: url('./assets/home_man.png'),
		linear-gradient(85deg, #f9fdff 60%, #147cbc calc(40% + 1px));
	background-size: 50% 70%, 95% 100%;
	background-repeat: no-repeat;
	background-position: right;
	background-position-y: 50%;
	object-fit: contain;
	width: 100%;
	position: relative;
	z-index: 1000;
}

.kenya-text {
	background: linear-gradient(to bottom, #000 35%, #c8001b 60%, #006f30 30%),
		linear-gradient(#000 35%, #c8001b 60%, #006f30 30%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
/* 
.menu-item {
	color: var(--Inactive-menu-item, #7792B7);
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 21px;
} */

.active {
	color: var(--nea-blue, #147cbc) !important;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 21px;
}

.card {
	width: 100%;
	border-radius: 14px 14px 0px 0px;
	text-align: center;
	user-select: none;
}

.cover-photo {
	position: relative;
	background: var(--darkBlue);
	background-size: cover;
	height: 92px;
	border-radius: 14px 14px 0px 0px;
}

.profile {
	position: absolute;
	width: 100px;
	bottom: -40px;
	left: 30%;
	border-radius: 50%;
	padding: 5px;
}

.profile-name {
	font-size: 20px;
	color: #000;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.card-main {
	width: 100%;
	border-radius: 16px 16px 0px 0px;
	user-select: none;
}

.cover-photo-main {
	position: relative;
	border-radius: 16px 16px 0px 0px;
	background: rgba(20, 124, 188, 0.1);
	background-size: cover;
	height: 160px;
}

.profile-main {
	position: absolute;
	width: 156px;
	bottom: -45%;
	left: 15%;
	border-radius: 50%;
	padding: 10px;
	background: #fff;
}

.prof-name {
	color: #141414;
	font-size: 46px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%; /* 84px */
}

.prof-desc {
	color: rgba(0, 0, 0, 0.7);
	font-size: 17px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%; /* 30px */
}

.prof-edit {
	color: #0873b9;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	text-decoration-line: underline;
}

.prof-card-name {
	color: #000;
	font-size: 32px;
	font-style: normal;
	font-weight: 700;
	line-height: 24.5px; /* 76.563% */
}

.prof-reg {
	color: var(--Text-Black, #333);
	font-size: 19px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.prof-job {
	color: var(--Text-Black, #333);
	font-size: 21px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.prof-loc {
	color: #7d7d7d;
	font-size: 21px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.prof-card-head {
	color: #333;
	font-size: 21px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.prof-card-text {
	color: var(--Text-Black, #333);
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.prof-card-badge {
	color: var(--NEA-Purple, #262168);
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 24.5px; /* 122.5% */
}

.prof-card-tips {
	color: #333;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.prof-cert-text {
	color: var(--nea-blue, #147cbc);
	font-size: 21px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	text-decoration-line: underline;
}

.prof-info-head {
	color: #333;
	font-size: 17px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.prof-with-dsablt {
	color: #000;
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.profile-basic-info {
	color: #000;
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.edit-prof-desc {
	color: var(--text-black, #222);
	font-size: 17px;
	font-style: normal;
	font-weight: 400;
	line-height: 120%; /* 26.4px */
}

.edit-step-head {
	color: #333;
	font-size: 30px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.edit-step-desc {
	color: var(--text-black, #222);
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 120%; /* 26.4px */
}

.label-info {
	color: var(--text-black, #222);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 120%; /* 26.4px */
}

.check-text {
	color: var(--text-black, #222);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 120%; /* 26.4px */
}

.dash-welcome {
	color: #333;
	font-size: 30px;
	font-style: normal;
	font-weight: 500;
	line-height: 140%; /* 50.4px */
}

.dash-welcome-text {
	color: #333;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%; /* 36px */
}

.dash-welcome-verify-text {
	color: var(--nea-blue, #147cbc);
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%; /* 33.6px */
}

.cv-desc {
	color: rgba(0, 0, 0, 0.7);
	font-size: 17px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%; /* 36px */
}

.job-count {
	color: #141414;
	font-size: 29px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%; /* 48px */
}

.job-apply-text {
	color: var(--nea-blue, #147cbc);
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%; /* 30px */
}

.job-filter-text {
	color: #141414;
	font-size: 21px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%; /* 36px */
}

.radio-head {
	color: #141414;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%; /* 31.5px */
}

.job-filter-border {
	border-radius: 3px;
	border: 1px solid rgba(20, 20, 20, 0.05);
	background: var(--White, #fff);
	box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.03);
}

.go-back {
	color: var(--text-black, #222);
	font-size: 21px;
	font-style: normal;
	font-weight: 700;
	line-height: 120%; /* 26.4px */
}

.job-info-header {
	color: #141414;
	font-size: 21px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%; /* 31.5px */
}

.job-info-header-cv {
	color: #141414;
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%; /* 36px */
}

.job-info-text {
	color: rgba(20, 20, 20, 0.7);
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%; /* 31.5px */
}

.job-info-text-head {
	color: rgba(20, 20, 20, 0.7);
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%; /* 31.5px */
}

.dot-list {
	list-style-type: disc;
	margin-left: 7.5%;
}

.app-status-info {
	color: #141414;
	font-size: 28px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%; /* 48px */
}

.app-status-text {
	color: #333;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%; /* 27px */
}

.app-intv-gray {
	color: #888;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%; /* 36px */
}

.app-intv-black {
	color: #141414;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%; /* 36px */
}

.int-reqs {
	color: #141414;
	font-size: 21px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%; /* 36px */
}

.num-list {
	list-style: none;
	counter-reset: item;
}

.num-list li {
	counter-increment: item;
}

.num-list li::before {
	margin-right: 10px;
	content: counter(item) '.';
}

.int-no {
	color: var(--nea-blue, #147cbc);
	font-size: 17px;
	font-style: normal;
	font-weight: 700;
	line-height: 24.5px; /* 122.5% */
}

.offer-text {
	color: #006f30;
	font-size: 21px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%; /* 36px */
}

.edit-header {
	color: var(--text-black, #222);
	font-size: 29px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%; /* 38.4px */
}

.edit-visible {
	color: var(--Text-Black, #333);
	font-size: 17px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.vis-text {
	color: #000;
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.private-text {
	color: var(--text-black, #222);
	font-size: 29px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%; /* 38.4px */
}

.sure {
	color: var(--Text, #333);
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%; /* 36px */
}

.sure-text {
	color: #333;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%; /* 36px */
}

.interview {
	color: #181818;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%; /* 36px */
}

.book {
	color: rgba(0, 0, 0, 0.7);
	font-size: 25px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%; /* 42px */
}

.book-text {
	color: rgba(0, 0, 0, 0.7);
	font-size: 21px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%; /* 36px */
}

.con-title {
	color: rgba(0, 0, 0, 0.7);
	font-size: 23px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%; /* 39px */
}

.req-btn {
	display: flex;
	width: 254.861px;
	height: 45px;
	padding: 0px 20px 0px 20px;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	border-radius: 35px;
	background: #eaeaea;
	box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12),
		0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2);
	color: #333;
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 24.5px; /* 122.5% */
}

.guide {
	color: var(--White, #fff);
	font-size: 19px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%; /* 36px */
}

.dash-info {
	color: #141414;
	font-size: 21px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%; /* 36px */
}

.btn-card {
	width: 100%;
	height: 190px;
	flex-shrink: 0;
	border-radius: 12px;
	border: 2px solid #edf8ff;
	background: var(--White, #fff);
	color: var(--Gray-1, #333);
	font-size: 19px;
	font-style: normal;
	font-weight: 700;
	line-height: 21px; /* 95.455% */
	text-transform: capitalize;
}

.btn-card-active {
	width: 100%;
	height: 190px;
	flex-shrink: 0;
	border-radius: 12px;
	border: 2px solid #edf8ff;
	background: #edf8ff;
	color: var(--darkBlue);
	font-size: 19px;
	font-style: normal;
	font-weight: 700;
	line-height: 21px; /* 95.455% */
	text-transform: capitalize;
	filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.not-pos {
	color: #141414;
	font-size: 17px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%; /* 27px */
	text-decoration-line: underline;
}

.not-inst {
	color: #141414;
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%; /* 24px */
}

.not-by {
	overflow: hidden;
	color: var(--black-100, #1c1c1c);
	font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-size: 17px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px; /* 111.111% */
}

.not-time {
	color: var(--black-40, rgba(0, 0, 0, 0.4));
	font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px; /* 112.5% */
}

.not-header {
	color: var(--black-100, #1c1c1c);
	font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
	font-size: 19px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px; /* 100% */
}

/* Hiding scrollbar for Chrome, Safari and Opera */
.content::-webkit-scrollbar {
    display: none;
}

/* Hiding scrollbar for IE, Edge and Firefox */
.content {
  scrollbar-width: none;  /* Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
}
