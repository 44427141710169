.slanted-background {
    background-blend-mode:lighten;
    background: url('../../assets/map.png'), linear-gradient(75deg, #147CBC 50%, #EDF8FF calc(50% + 1px));
    background-size: 60% auto, 100% 100%;
    background-repeat: no-repeat;
    background-position: start;
    background-position-y: 80%;
    width: 100%;
    height: 100vh;
}

 