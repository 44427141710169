@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&display=swap');

:root {
	--newBg: #f9fdff;
	--mainBg: #edf8ff;
	--darkBlue: #147cbc;
	--blue: #0873b9;
	--blueish: #f2faff;
	--lightBlue: #edf8ff;
	--softBlue: #e8f4ff;
	--blackest: #141414;
	--black: #000;
	--black333: #333;
	--black2: #222;
	--black3: rgba(20, 20, 20, 0.7);
	--gray: #5a5a5a;
	--darkPurple: #7d5ae2;
	--lightPurple: #e3e1ff;
	background-color: var(--newBg) !important;
	font-family: 'DM Sans', sans-serif !important;
	overflow: hidden;
}

html {
	background-color: var(--newBg) !important;
	font-family: 'DM Sans', sans-serif !important;
}

body {
	background-color: var(--newBg) !important;
	font-family: 'DM Sans', sans-serif !important;
}

textarea {
	border-radius: 8px !important;
	border: 1px solid #000 !important;
}

label.ant-form-item-required {
	color: var(--text-black, #222);
	font-family: 'DM Sans', sans-serif !important;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%; /* 26.4px */
}

label {
	color: var(--text-black, #222);
	font-family: 'DM Sans', sans-serif !important;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
}

.ant-form-item-required::before {
	content: '' !important;
}

.ant-drawer-header-title {
	flex-direction: row-reverse;
}

.job-drawer .ant-drawer-header {
	display: none !important;
}

.job-drawer .ant-drawer-body {
	padding: 0 !important;
	overflow: hidden !important;
}

.ant-modal-content {
	padding: 0 !important;
	z-index: 1000;
	max-height: 100%;
	height: auto;
}

.ant-modal-body {
	padding: 20px 37px !important;
	background: inherit;
}

.ant-modal-close-x {
	color: white !important;
	font-weight: 500;
}

.ant-modal-title {
	background: var(--darkBlue);
	color: white !important;
	padding: 20px 17px;
	font-size: 22px;
	font-weight: 500;
	letter-spacing: -0.462px;
	border-radius: 5px 5px 0px 0px;
}

.ant-select-selector {
	/* height: 44px !important; */
	/* padding: 4px !important; */
	border: 1px solid #000 !important;
}

.search-select .ant-select-selector {
	border: none !important;
}

.ant-modal-footer {
	padding: 20px !important;
}

/* .ant-steps {
	display: none !important;
} */

.ant-input-number-input {
	height: 42px !important;
}

.ant-input-number-handler-wrap {
	display: none !important;
}

.ant-upload-icon > .anticon,
.ant-upload-list-item-name {
	color: #0a0a0a !important;
}

.ant-tabs-tab {
	background-color: #fff !important;
}

.ant-tabs-tab-active {
	background-color: var(--bs-dark) !important;
	color: var(--bs-orange) !important;
}

.ant-tabs-tab-active span {
	color: var(--bs-orange) !important;
	padding-left: 5px !important;
	padding-right: 5px !important;
}

.ant-tabs-tab-btn span {
	color: #000 !important;
	font-size: 17px !important;
	font-style: normal;
	font-weight: 700 !important;
	line-height: 24.5px; /* 116.667% */
}

.ant-picker-range {
	width: 100% !important;
}

.editable-cell {
	position: relative;
}

.editable-cell-value-wrap {
	padding: 5px 12px;
	cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
	padding: 4px 11px;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
}

.ant-radio-inner {
	border-color: #147cbc !important;
}

.ant-table
	.ant-table-container
	.ant-table-content
	table
	thead.ant-table-thead
	.ant-table-cell {
	background-color: #f2faff;
	color: #000;

	font-size: 15px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%; /* 31.5px */
	border: none !important;
}

.no-header-bg-table
	.ant-table-container
	.ant-table-content
	table
	thead.ant-table-thead
	.ant-table-cell {
	background-color: transparent;
	color: #000;
	font-size: 15px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%; /* 31.5px */
	border: none !important;
}

:where(.css-dev-only-do-not-override-3mqfnx).ant-table-wrapper
	.ant-table-container
	table
	> thead
	> tr:first-child
	> *:first-child {
	border-start-start-radius: 0px;
}

:where(.css-dev-only-do-not-override-3mqfnx).ant-table-wrapper
	.ant-table-container
	table
	> thead
	> tr:first-child
	> *:last-child {
	border-start-end-radius: 0px;
}

.ant-table-tbody > tr > td {
	color: #141414;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%; /* 29.4px */
}

.ant-table-title {
	width: 100%;
	height: 44px;
	background-color: var(--darkBlue);
}

.ant-tabs-tab-active span {
	color: var(--darkBlue);
	font-size: 15px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%; /* 31.5px */
}

.ant-tabs-tab-btn span {
	color: var(--black333);
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%; /* 31.5px */
}

.ant-tabs-tab-btn .material-icons {
	color: var(--black333) !important;
}

.ant-tabs-tab-active .material-icons {
	color: var(--darkBlue) !important;
}

.app-status-tab .ant-tabs-nav-list {
	width: 100%;
	justify-content: space-between;
}

.app-status-tab .ant-tabs-nav-wrap{
	background: #fff !important;
}

.app-status-tab .ant-tabs-tab {
	padding: 12px 25px !important;
}

.ant-tabs-ink-bar {
	width: 120px !important;
	height: 5px !important;
	background: var(--darkBlue) !important;
	border-radius: 8px;
}

.tabless .ant-tabs-nav-wrap{
	display: none !important;
}

.tabless .ant-tabs-nav{
	display: none !important;
}

.ant-menu-item-selected {
	background: #f5fbffba !important;
}

.ant-menu-item {
	color: var(--Inactive-menu-item, #7792b7);
}

.ant-menu-item .ant-menu-item-icon {
	fill: var(--Inactive-menu-item, #7792b7);
}

.ant-menu-item-selected span {
	color: var(--darkBlue) !important;
}

.ant-menu-item-selected .ant-menu-item-icon {
	fill: var(--darkBlue) !important;
	color: var(--darkBlue) !important;
}

.ant-menu {
	border-inline-end: none !important;
}

.ant-dropdown-menu {
	padding: 20px !important;
}

.ant-dropdown-menu-item {
	margin: 20px 10px !important;
}

.ant-steps-item-process .ant-steps-item-icon {
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
	width: 70px !important;
	height: 70px !important;
	border-radius: 50% !important;
	margin-inline-start: 0px !important;
	background: #d6efff !important;
	border-color: var(--darkBlue) !important;
	border: 3px solid var(--darkBlue) !important;
}

.ant-steps-item-wait .ant-steps-item-icon {
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
	width: 70px !important;
	height: 70px !important;
	border-radius: 50% !important;
	margin-inline-start: 0px !important;
	background: #fff !important;
	border-color: var(--darkBlue) !important;
	border: 3px solid var(--darkBlue) !important;
}

.ant-steps-item-finish .ant-steps-item-icon {
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
	width: 70px !important;
	height: 70px !important;
	border-radius: 50% !important;
	margin-inline-start: 0px !important;
	background: var(--darkBlue) !important;
	border-color: var(--darkBlue) !important;
	border: 3px solid var(--darkBlue) !important;
}

.ant-steps-icon {
	color: var(--nea-blue, #147cbc) !important;
	font-size: 18px !important;
	font-style: normal;
	font-weight: 500 !important;
	line-height: 150%; /* 33px */
	letter-spacing: -0.242px;
}

.ant-steps-item-tail {
	top: 30% !important;
	padding: 4px 14px !important;
	/* height: 1px !important;
	background: var(--darkBlue) !important; */
}

.ant-steps-item-tail::after {
	height: 2px !important;
	background: #0873b9 !important;
	border-radius: 1px;
}

.ant-steps-item-content {
	text-align: left !important;
}

.ant-steps-item-title {
	color: #0873b9 !important;
	font-size: 16px !important;
	font-style: normal;
	font-weight: 500 !important;
	line-height: 150%; /* 33px */
	letter-spacing: -0.242px !important;
}

.ant-steps-item-finish .ant-steps-icon {
	color: #fff !important;
	font-size: 18px !important;
	font-style: normal;
	font-weight: 500 !important;
	line-height: 150%; /* 33px */
	letter-spacing: -0.242px;
}

.ant-picker {
	width: 100%;
	height: 44px !important;
	border: 1px solid #000 !important;
}

.doc-date-picker {
	height: 64px !important;
}

.ant-checkbox-inner {
	width: 28px !important;
	height: 28px !important;
	margin-top: -4px !important;
	border-radius: 4px !important;
	border: 1px solid var(--nea-blue, #147cbc) !important;
}

.ant-checkbox .ant-checkbox-inner::after {
	width: 8px !important;
	height: 12px !important;
}

.ant-upload-drag {
	border-radius: 1px !important;
	border: 1px dashed var(--nea-blue, #147cbc) !important;
	background: var(--White, #fff) !important;
	height: 64px !important;
}

.ant-upload-text {
	color: var(--nea-blue, #147cbc) !important;
	font-size: 18px !important;
	font-style: normal;
	font-weight: 500 !important;
	line-height: normal;
}

.upload-input .ant-upload-drag {
	border-radius: 8px !important;
	border: 1px solid var(--black) !important;
	background: var(--White, #fff) !important;
	height: 44px !important;
}

.ant-spin-dot-item {
	background-color: white !important;
}

.ant-tag {
	color: #262168 !important;
	font-size: 15px !important;
	font-weight: 500 !important;
	background: #e3e1ff !important;
}

.ant-tag-close-icon {
	color: #262168 !important;
}

.job-filter input::placeholder {
	color: #9b9b9e !important;
	font-size: 16px !important;
	font-style: normal;
	font-weight: 700 !important;
	line-height: 24.5px; /* 116.667% */
}

.job-tab .ant-tabs-nav-wrap {
	width: 100% !important;
	background-color: #fff !important;
	padding: 15px 20px;
}

.job-tab .ant-tabs-tab-active {
	background-color: var(--bs-dark) !important;
	color: var(--bs-orange) !important;
	border-bottom: 5px solid #147cbc !important;
}

.job-tab .ant-tabs-ink-bar {
	display: none;
}

.job-tab .ant-tabs-nav-list {
	width: 100% !important;
	display: flex !important;
	flex-direction: row !important;
	justify-content: space-between !important;
}

.ant-picker-dropdown{
	width: 300px;
}

.ant-picker-footer{
	width: 300px !important;
}

.ant-picker-ok .ant-btn{
	color: var(--black);
	border: 2px solid blue;
    width: 50px;
}

.ant-switch{
	background: #E2E8F0
}

.ant-switch-checked{
	background: var(--darkBlue) !important;
}

.ant-switch:hover{
	border: 1px solid #64748b !important;
	background: #fff !important;
}

.ant-switch-handle::before{
	background-color: #64748b !important;
}

.ant-switch-checked .ant-switch-handle::before{
	background-color: #fff !important;
}